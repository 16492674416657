/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Vvoden from "@components/sharedComponents/customersLogos/logos/Vvoden.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import E4CC from "@components/sharedComponents/customersLogos/logos/E4CC.svg"
import Farenet from "@components/sharedComponents/customersLogos/logos/Farenet.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/plataforma-score.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/soluciones-telefonicas-para-empresas.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/software-cloud-pbx.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/chatgpt-contact-center.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/indicadores-de-productividad.jpg"

// logos testimonios
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import MorneseTes from "@components/sharedComponents/testimonies/images/logos/mornese.svg"
import ConexoTes from "@components/sharedComponents/testimonies/images/logos/conexo.svg"
import RecuperaTes from "@components/sharedComponents/testimonies/images/logos/recupera.svg"

//foto testimonio desktop
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import morneseDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/mornese.png"
import conexoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/recupera.png"
import recuperaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/recupera.png"

//foto testimonio desktop
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import morneseMobile from "@components/sharedComponents/testimonies/images/fotosMobile/mornese.png"
import conexoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/recupera.png"
import recuperaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/recupera.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageContactCenter/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageContactCenter/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageContactCenter/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageContactCenter/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageIndustrias/pageContactCenter/images/accordionLeft/imageFive.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageContactCenter/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageContactCenter/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageContactCenter/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageIndustrias/pageContactCenter/conversions/images/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageContactCenterIA.jpg"

import { TitleUnderlineContactCenterOne, TitleUnderlineContactCenterTwo } from "@components/pageCasosdeUso/components/intro/titleUnderlineContactCenter"
import imageStartContactCenter from "@components/pageCasosdeUso/components/intro/images/imageStartContactCenter.webp"


const titleStart = (
  <p>
    Mejora la contactabilidad de tu Contact Center con <TitleUnderlineContactCenterOne underline="omnicanalidad e IA" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Alcanza tus objetivos comerciales en tus campañas inbound y outbound mejorando el contacto con tus usuarios mediante un enfoque omnicanal. Implementa fácilmente autoservicio con IVR y chatbots potenciados con IA.
        <br /><br />
        Lanza campañas telefónicas con nuestros marcadores inteligentes y mejora tus métricas de servicio, ventas y cobros. Supervisa tu operación en tiempo real y bríndales a tus agentes funcionalidades que mejoren su productividad.</p>
    ),
    image: imageStartContactCenter,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Alfin,
    Prestamype,
    Civa,
    Synlab,
    Vvoden,
    Conexo,
    Eriza,
    E4CC,
    Farenet
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Alfin, Prestamype, Civa,],
    arrayTwo: [Synlab, Vvoden, Conexo, Eriza, E4CC, Farenet],
  },
  articles: [
    {
      image: article1,
      title: "Beex Contact Center: qué es y cómo funciona",
      textbody:
        "Descubre qué es esta plataforma y cómo puede elevar la productividad y la calidad de tus interacciones con tus clientes.",
      link: "https://beexcc.com/blog/plataforma-score/",
      card: true,
    },
    {
      image: article2,
      title: "10 soluciones telefónicas que debes conocer en el 2024",
      textbody:
        "Conoce 10 soluciones telefónicas para empresas con esta lista de buenas opciones.",
      link: "https://beexcc.com/blog/soluciones-telefonicas-para-empresas/",
      card: true,
    },
    {
      image: article3,
      title: "Top 10 de Software Cloud PBX para empresas en el 2024",
      textbody:
        "Eleva la eficiencia de tu Contact Center con esta selección de los 10 mejores Software Cloud PBX.",
      link: "https://beexcc.com/blog/software-cloud-pbx/",
    },
    {
      image: article4,
      title: "¿Cómo usar ChatGPT para Contact Center? 5 casos",
      textbody:
        "Descubre 5 casos de uso de ChatGPT para Contact Center y ofrece a tus clientes una atención de primer nivel.",
      link: "https://beexcc.com/blog/chatgpt-contact-center/",
    },
    {
      image: article5,
      title: "10 indicadores de productividad en un Contact Center",
      textbody:
        "Conoce 10 indicadores de productividad para elevar la eficiencia de tu Contact Center, desde la gestión de tiempo hasta la calidad de atención.",
      link: "https://beexcc.com/blog/indicadores-de-productividad/",
    },
  ],
  contacts: {
    title: "Potencia tus operaciones de Contact Center con tecnología cloud",
    textbody: "Cuéntanos sobre tus operaciones outbound o inbound para que nuestros asesores te brinden un feedback que te permita mejorar tus servicios.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: NetcallTes,
      title: "Netcall duplicó sus ventas y aumentó en un 25% su contactabilidad con Beex ",
      link: "/casos-de-exito/netcall-peru/",
      industrie: "",
      backgroundIndustrie: "",
      backgroundFondo: "#EC460033",
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: MorneseTes,
      title: "Mornese gestionó 3 veces más rápido sus bases de cobros con Beex  ",
      link: "/casos-de-exito/mornese/",
      industrie: "",
      backgroundIndustrie: "",
      backgroundFondo: "#DF020933",
      metrics: [
        {
          metric: "+47%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+35%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de sus bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: RecuperaTes,
      title: "Grupo Recupera aumentó en un 97% su tasa de ocupación con el marcador predictivo con de Beex ",
      link: "/casos-de-exito/grupo-recupera/",
      industrie: "",
      backgroundIndustrie: "",
      backgroundFondo: "#01648333",
      metrics: [
        {
          metric: "+75%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#FFB800",
        },
        {
          metric: "+28%",
          descripcion: "aumentó su contactabilidad",
          color: "#5831D8",
        },
        {
          metric: "90%",
          descripcion: "logró de índice de adherencia",
          color: "#00CA56",
        },
      ],
    },
  ],
  accordionRight: [
    {
      title: "Crea campañas en simples pasos",
      description: "<p>Con nuestra interfaz intuitiva y fácil de usar, permite a tus usuarios crear y lanzar campañas de llamadas o mensajería de manera rápida y sencilla. Utiliza interfaces drag and drop, plantillas predefinidas y opciones de personalización que te aseguren la tasa de contacto más alta.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Mejora tus operaciones con IA",
      description: "<p>Utiliza la inteligencia artificial para el análisis de sentimientos y comportamiento en tiempo real durante las interacciones con los clientes. Además, configura enrutamiento inteligente basado en aprendizaje automático y sugerencias de respuestas para chatbots y agentes.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones escalables",
      description: "<p>Utiliza nuestra API Cloud para realizar integraciones ilimitadas con las herramientas empresariales que tú desees. Contamos con una interfaz que asegura una conectividad sin fisuras permitiendo la sincronización bidireccional de datos para una operación eficiente.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Contactabilidad automatizada",
      description: "<p>Mejora tu tasa de contacto programando llamadas y mensajes en momentos óptimos, reintentar contactos no respondidos de manera automatizada, ajustar la frecuencia de contacto según el comportamiento, entre otros. Hazlo por los canales de mejor contacto, como llamadas, WhatsApp y más.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Dashboards dinámicos",
      description: "<p>Utiliza paneles de control personalizables y dinámicos que muestran información en tiempo real sobre el desempeño de tus campañas. Utiliza paneles con métricas clave como tiempos de espera, niveles de servicio, etc. y permite a los usuarios filtrar datos de manera que sea más relevante para sus roles y objetivos.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "¿Cómo automatizar un Contact Center con Beex? ",
    description: "<p>Descubre cómo puedes simplificar y optimizar la gestión de tu Contact Center utilizando la plataforma Beex. <br /><br /> Aprende los pasos clave para implementar la automatización y qué herramientas necesitas para mejorar la eficiencia en tus operaciones diarias. Conoce cómo integrar tecnología en tu Contact Center. </p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Envía recordatorios de pago",
      img: imageConversionsOne,
      text:
        "Programa y envía recordatorios de pago personalizados a los deudores de manera automática a través de diferentes canales, como mensajes de texto, correo electrónico o llamadas telefónicas. Añade parámetros que incluyan información específica del deudor y mejora tu tasa de apertura.",
    },
    {
      title: "Segmenta a los deudores",
      img: imageConversionsTwo,
      text:
        "Utiliza datos históricos para segmentar a los deudores en diferentes grupos según su historial de pago, nivel de riesgo y preferencias de comunicación. Diseña estrategias de cobranza personalizadas y adaptadas a las necesidades de cada segmento de clientes, como preventiva, castigada o más.",
    },
    {
      title: "Gestiona las promesas de pago",
      img: imageConversionsThree,
      text:
        "Obtén una visión 360 del estado de las promesas de pago de cada cliente. Actualiza rápidamente los acuerdos de pago y envía recordatorios automáticos por WhatsApp o SMS antes de las fechas de vencimiento acordadas.",
    },
    {
      title: "Predice las respuestas",
      img: imageConversionsFour,
      text:
        "Utiliza nuestros modelos de Machine Learning para predecir las respuestas y comportamientos de los clientes frente a diferentes estrategias de cobranza. Esto ayuda a anticipar posibles obstáculos y ajustar las tácticas de comunicación para maximizar la recuperación.",
    },
  ],
}

export default data
